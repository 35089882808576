import React from "react"

export function QuestionIco() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.96297 5.9C6.12755 5.43217 6.45238 5.03767 6.87994 4.78639C7.3075 4.53511 7.8102 4.44326 8.299 4.5271C8.78779 4.61094 9.23114 4.86507 9.55053 5.24447C9.86991 5.62387 10.0447 6.10407 10.044 6.6C10.044 8 7.94397 8.7 7.94397 8.7M8.00002 11.5H8.00702M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
