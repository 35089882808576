const isObject = (data) =>
    Object.prototype.toString.call(data) === "[object Object]"
const isArray = (data) =>
    Object.prototype.toString.call(data) === "[object Array]"
const flatten = (data) => {
    if (!data.attributes) return data
    
    const res = {
        ...data.attributes
    }
    if (data.id) {
        res.id = data.id
    }
    return res
}
const normalize = (data) => {
    if (isArray(data)) {
        return data.map((item) => normalize(item))
    }
    
    if (isObject(data)) {
        if (isArray(data.data)) {
            data = data.meta ? { meta: data.meta, data: [...data.data] } : [...data.data]
        } else if (isObject(data.data)) {
            data = flatten({ ...data.data })
        } else if (data.data === null) {
            data = null
        } else {
            data = flatten(data)
        }
        
        for (const key in data) {
            data[key] = normalize(data[key])
        }
        
        return data
    }
    
    return data
}

module.exports.normalize = normalize
