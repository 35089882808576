import React, { useEffect, useRef, useState } from "react"
import css from "@css/Components.module.scss"
import { Popover } from "antd"
import { QuestionIco } from "@css/icons/QuestionIco"
import { ArrowLeftThin } from "@css/icons/SVG"
import cn from "classnames"
import { groups } from "../SoloResortChart/prepareData"

const ppGroups = Object.values(groups).map((i) => {
    return {
        min: i.from,
        max: i.to
    }
})

export function PointsSelect({ onChange, lastUpdate, defaultActive = 0 }) {
    /***
     * @type {React.MutableRefObject<HTMLUListElement>}
     */
    const ref = useRef()
    const scrollRoot = (right) => {
        if (ref.current) {
            const width = ref.current.parentElement.offsetWidth / 2
            ref.current.scrollBy(right ? width : -width, 0)
        }
    }
    const [active, setActive] = useState(defaultActive)
    const onClick = (e) => {
        const index = e.target.dataset.index
        setActive(+index)
    }
    useEffect(() => {
        onChange(ppGroups[active].max - 1)
    }, [active])
    const content = (
        <div className={css.popContent}>
            Generally, smaller contracts fetch a higher price per point compared to larger contracts which sell for a lower price per point. The average contract size is 160 points
        </div>
    )
    return (
        <div className={css.bottom}>
            <div className={css.bottomInfo}>
                Size of the contract, in points
                <Popover content={content} trigger="hover" overlayClassName={css.chartInfoPopover}>
                    <span>
                        <QuestionIco />
                    </span>
                </Popover>
            </div>
            <div className={css.bottomContent}>
                <div className={css.pointsListContainer}>
                    <button className={css.arrow} onClick={() => scrollRoot()} aria-label={"scroll left"}>
                        <ArrowLeftThin />
                    </button>
                    <button className={css.arrow} onClick={() => scrollRoot(true)} aria-label={"scroll right"}>
                        <ArrowLeftThin />
                    </button>
                    <ul ref={ref} className={css.pointsList}>
                        {ppGroups.map((i, index) => {
                            return (
                                <li
                                    key={index}
                                    id={"point-index-" + index}
                                    className={cn({
                                        [css.pointItem]: true,
                                        [css.pointItemActive]: index === active
                                    })}
                                    onClick={onClick}
                                    data-index={index}>
                                    {i.min}-{i.max}
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className={css.lastUpdate}>Last updated: {lastUpdate}</div>
            </div>
        </div>
    )
}
