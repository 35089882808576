import { useEffect, useRef, useState } from "react"
import styles from "@css/modules/Listing.module.scss"
import { CategoryScale, Chart, LinearScale, LineController, LineElement, PointElement, Tooltip } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import Image from "next/image"
import cn from "classnames"

Chart.register(LineElement, PointElement, LineController, CategoryScale, LinearScale, Tooltip, ChartDataLabels)
Chart.defaults.set("plugins.datalabels", {
    color: "transparent"
})
/**
 * @type {import("chart.js").GridLineOptions}
 */
const gridConf = {
    borderWidth: 2,
    color: "#3B3060",
    borderColor: "#3B3060",
    display: true,
    drawBorder: true,
    drawOnChartArea: true,
    tickWidth: 2,
    circular: true,
    borderDash: () => [6, 4],
    drawTicks: true
}

function updateArray(first, second) {
    first.splice(0, first.length, ...second.slice(0, first.length))
    if (second.length > first.length) {
        first.push(...second.slice(first.length))
    }
}

/**
 * Renders a solo resort chart component.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} [props.right] - Whether to display the right component.
 * @param {JSX.Element} [props.bottom] - Whether to display the bottom component.
 * @param {Object} [props.chartOptions] - The chart options.
 * @param {boolean} [props.showHeaderToggle] - Whether to show the header toggle.
 * @param {string} [props.title] - The title of the chart.
 * @param {string} props.subTitle - The subtitle of the chart.
 * @param {Object} props.data - The data for the chart.
 * @param {boolean} [props.withCurrent] - Whether to display the current data.
 * @param {Object} [props.classNames] - The class names for the component.
 * @param {boolean} [props.withUpdates] - Whether to display updates.
 * @return {JSX.Element} The rendered solo resort chart component.
 */
export function SoloResortChart({ right, bottom, chartOptions, showHeaderToggle = true, title = "Market report", subTitle, data, withCurrent, classNames, withUpdates = false }) {
    /**
     * @type {React.MutableRefObject<HTMLCanvasElement>}
     */
    const ref = useRef()
    /**
     * @type {React.MutableRefObject<Chart>}
     */
    const chartRef = useRef()
    const [displayed, setDisplayed] = useState({
        DD: false,
        ARP: true,
        CURRENT: true
    })
    useEffect(() => {
        if (chartRef.current?.options) {
            Object.assign(chartRef.current?.options, chartOptions || {})
            chartRef.current?.update()
        }
    }, [chartOptions])
    useEffect(() => {
        const width = ref.current.parentElement.offsetWidth
        const height = ref.current.parentElement.offsetHeight
        if (!chartRef.current && data) {
            Object.assign(ref.current, {
                width,
                height
            })
            chartRef.current = new Chart(ref.current, {
                type: "line",
                data: data,
                options: {
                    color: "#CFCFCF",
                    responsive: true,

                    layout: {
                        padding: {
                            top: 25,
                            right: 20
                        }
                    },
                    width,
                    height,
                    aspectRatio: 1,
                    plugins: {
                        tooltip: {
                            backgroundColor: "rgba(0,0,0,.6)",
                            callbacks: {
                                title() {
                                    return ""
                                },
                                label: function (tooltipItem) {
                                    const label = tooltipItem.dataset.label
                                    const value = Number(tooltipItem.formattedValue).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 1
                                    })
                                    return " " + label + ": " + value
                                }
                            }
                        },
                        legend: {
                            display: false
                        }
                    },

                    scales: {
                        x: {
                            grid: gridConf,
                            ticks: {
                                font: { size: 12 },
                                autoSkipPadding: true,
                                autoSkip: true,
                                color: "#CFCFCF"
                            }
                        },
                        y: {
                            grid: gridConf,
                            ticks: {
                                /**
                                 * @param value {number}
                                 * @returns {string}
                                 */
                                callback: function (value) {
                                    return value.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 })
                                },
                                font: { size: 12 },
                                color: "#CFCFCF"
                            }
                        }
                    },
                    ...chartOptions
                }
            })
        }
    }, [data, chartOptions])
    useEffect(() => {
        ref.current.addEventListener("mouseenter", () => {
            if (chartRef.current) {
                chartRef.current.showDatalabels = true
                chartRef.current.update()
            }
        })
        ref.current.addEventListener("mouseleave", () => {
            if (chartRef.current) {
                chartRef.current.showDatalabels = false
                chartRef.current.update()
            }
        })
        return () => {
            chartRef.current?.destroy()
            chartRef.current = null
        }
    }, [])
    useEffect(() => {
        if (withUpdates && chartRef.current && data?.labels) {
            updateArray(chartRef.current.data.labels, data.labels)
            updateArray(
                chartRef.current.data.datasets,
                data.datasets.filter((i) => !!i.data.length && i.enabled)
            )
            chartRef.current.update("none")
            chartRef.current.update()
        }
    }, [withCurrent, data])

    const onToggle = (key, index) => () => {
        setDisplayed((state) => {
            const newVal = !state[key]
            if (newVal) {
                chartRef.current.show(index)
            } else {
                chartRef.current.hide(index)
            }
            return {
                ...state,
                [key]: newVal
            }
        })
    }
    return (
        <div className={cn(styles.linearChart, classNames?.root)}>
            <div className={styles.linearChartHead}>
                <div className={styles.titleWrap}>
                    <h2 className={styles.title}>{title}</h2>
                    <p className={styles.subTitle}>{subTitle}</p>
                </div>
                {showHeaderToggle && (
                    <div className={styles.labels}>
                        <div className={cn(styles.label, displayed.ARP && styles.active)} onClick={onToggle("ARP", 2)}>
                            <span className={styles.avg} />
                            <div>Avg Resale Price</div>
                        </div>
                        <div className={cn(styles.label, displayed.CURRENT && styles.active)} onClick={onToggle("CURRENT", 1)}>
                            <Image src="/star.svg" alt="star" width={20} height={20} id={"star-img"} />
                            <div>This Contract</div>
                        </div>
                    </div>
                )}
            </div>
            <div className={cn(styles.bodyWrapper, classNames?.bodyWrapper)}>
                <div className={cn(styles.chartParent, classNames?.chartParent)}>
                    <canvas id="chart" ref={ref} />
                </div>
                {right}
            </div>
            {bottom}
        </div>
    )
}
