import moment from "moment"
import { fetchRest } from "@lib/fetchRest"

export async function getPriceStatistic(resort: number) {
    const monthBefore = moment().add(-12, "month")
    const { data } = await fetchRest(
        "price-statistics",
        {
            fields: ["id", "date", "averagePrice", "averagePP", "price_per_point", "points_on_contract"],
            filters: {
                resort: {
                    id: {
                        $eq: resort
                    }
                },
                date: {
                    $notNull: true,
                    $gte: monthBefore.toISOString(true)
                }
            },
            pagination: {
                limit: 100
            },
            sort: "createdAt:asc"
        },
        undefined,
        86400 // one day cache
    )
    return { priceStatistics: data }
}
