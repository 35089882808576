import cn from "classnames"
import styles from "@css/Components.module.scss"
import { Radio } from "@css/icons/SVG"

export function CheckButtons({ items = [], onChange, classes = {}, values, setRef }) {
    const { root, label } = classes
    return (
        <div className={cn(root, styles.radioRoot)} ref={setRef}>
            {items.map((i) => {
                return (
                    <label key={i.id} className={cn(label, styles.label)} style={i.style}>
                        <input onChange={onChange} checked={!!values?.[i.id]} type={"checkbox"} value={i.value} />
                        <Radio />
                        {i.label}
                    </label>
                )
            })}
        </div>
    )
}
