const currencyFormat = new Intl.NumberFormat("en-US", { currency: "USD", style: "currency", minimumFractionDigits: 0 })

/**
 * @param amount {number}
 * @returns {string}
 */
export function addCurrency(amount) {
    return currencyFormat.format(amount)
}

const monthFormat = new Intl.DateTimeFormat("en-US", { month: "short" })

/**
 * @param month {string}
 * @returns {string}
 */
export const formatMonth = (month) => {
    try {
        return monthFormat.format(new Date(`01 ${month} 2020`))
    } catch (err) {
        console.warn(err)
        return month
    }
}

/**
 * @param month {string}
 * @returns {number}
 */
export function getMonthNum(month) {
    return new Date(`01 ${month} 2020`).getMonth()
}

const dateFormatter = new Intl.DateTimeFormat("en-US", { month: "short", day: "numeric", year: "numeric" })

export function dateFormat(date) {
    try {
        return dateFormatter.format(new Date(date))
    } catch (err) {
        console.log("wrong date", date)
        return ""
    }
}

/**
 * @param date {string} - date string
 * @return {number} - integer and min 1
 */
export function daysDiff(date) {
    const date1 = new Date()
    const date2 = new Date(date)
    const diffTime = Math.abs(date2 - date1)
    return Math.max(Math.ceil(diffTime / (1000 * 60 * 60 * 24)), 1)
}

/**
 * @param time
 * @return {Promise<unknown>}
 */
export const waiter = (time) => new Promise((res) => setTimeout(res, time))

/**
 * run function with min delay
 * @param func {function} - function to delay
 * @param min {number} - milliseconds
 * @return {Promise<*>}
 */
export const delay = async (func, min = 200) => {
    const start = Date.now()
    const result = await func()
    const diff = Date.now() - start
    if (diff >= min) {
        return result
    } else {
        const waitTime = min - diff
        await waiter(waitTime)
        return result
    }
}

/**
 * @function executor
 * @property { function(): void } destroy
 * @return {any}
 */
/**
 * @param fn {function(): void}
 * @param limit {number} - milliseconds
 * @return {executor}
 */
export function callLimit(fn, limit = 1000) {
    let lastCalled
    let interval
    function executor() {
        const now = Date.now()
        clearTimeout(interval)
        if (!lastCalled) {
            lastCalled = now
            fn()
            return
        }
        const diff = now - lastCalled
        if (diff > limit) {
            lastCalled = now
            fn()
        } else {
            interval = setTimeout(fn, limit - diff)
        }
    }
    executor.destroy = () => {
        clearTimeout(interval)
    }
    return executor
}

class RObserver {
    _resizeList = {}
    _interval
    _initialized = false
    _onResize = () => {
        clearInterval(this._interval)
        setTimeout(() => {
            Object.values(this._resizeList).forEach((i) => i())
        }, 100)
    }

    _init() {
        window.addEventListener("resize", this._onResize)
        this._initialized = true
    }

    add = (cb) => {
        if (!this._initialized) {
            this._init()
        }
        const id = Date.now() + " " + Math.random()
        this._resizeList[id] = cb
        return () => {
            delete this._resizeList[id]
        }
    }
}

export const rObserver = new RObserver()

const blogFormat = new Intl.DateTimeFormat("en-US", { month: "long", day: "numeric" })

export function getBlogDate(d) {
    try {
        if (!d) return null

        const date = parseISOString(d)
        const pairs = blogFormat.formatToParts(date).reduce((res, i) => {
            res[i.type] = i.value
            return res
        }, {})
        return `${pairs.month} ${pairs.day} Report`
    } catch (e) {
        console.error(e)
        return null
    }
}
function parseISOString(s) {
    var b = s.split(/\D+/)
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
}
