"use client"
import { createContext, useContext } from "react"
import type { GeneralData as GD } from "@t/GeneralData"

const GeneralDataContext = createContext<GD>({} as GD)
export function useGeneralData() {
    return useContext(GeneralDataContext)
}

export function GeneralData({ children, gd }) {
    return <GeneralDataContext.Provider value={gd}>{children}</GeneralDataContext.Provider>
}
