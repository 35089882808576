import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { resortNames } from "@utils/const"
import { Select } from "antd"
import css from "@css/Components.module.scss"
import { CheckButtons } from "../../../CheckButtons"
import { orderBy } from "lodash"

const checkboxClasses = {
    root: css.resortsRadio,
    label: css.resortLabel
}

export function ResortSelect({ resortsById, defaultSelected = {}, onChange, isMobile }) {
    const [state, setState] = useState(defaultSelected)

    const options = useMemo(
        () =>
            orderBy(Object.values(resortsById), "short_name").map((i) => {
                return {
                    id: i.id,
                    value: i.id,
                    style: {
                        "--color": i.color
                    },
                    label: (
                        <>
                            {isMobile && <span style={{ "--bg": i.color }} className={css.labelChip} />}
                            {resortNames[i.short_name] || i.short_name}
                        </>
                    )
                }
            }),
        [isMobile, resortsById]
    )
    const values = useMemo(() => {
        return options.filter((i) => state[i.id])
    }, [state, options])
    const changeHandler = useCallback((e) => {
        if (Array.isArray(e)) {
            const res = {}
            e.forEach((k) => {
                res[k] = true
            })
            setState(res)
        } else {
            setState((s) => {
                return {
                    ...s,
                    [e.target.value]: e.target.checked
                }
            })
        }
    }, [])
    const prevState = useRef(false)
    useEffect(() => {
        if (prevState.current) {
            onChange(
                Object.keys(state)
                    .map((i) => !!state[i] && i)
                    .filter((i) => i !== false)
            )
        }
        prevState.current = true
    }, [state, onChange])
    const content = isMobile ? (
        <Select
            aria-label="Select resort"
            onChange={changeHandler}
            className={css.selectResort}
            mode="multiple"
            showSearch={false}
            allowClear
            placeholder="Please select resort"
            value={values}
            options={options}
        />
    ) : (
        <CheckButtons onChange={changeHandler} classes={checkboxClasses} values={state} items={options} />
    )
    return <div className={css.right}>{content}</div>
}
