import { isBrowser } from "../../../../utils/const"
import { prepareDataMultiple } from "../MultipleResortChart/utils"
import { addCurrency } from "../../../../utils"

const skipped = (ctx, value) => {
    if (ctx.p0.skip || ctx.p1.skip) {
        return value
    }
    return undefined
}
export const segment = {
    borderDash: (ctx) => skipped(ctx, [0, 0])
}

export const groups = {
    low1: {
        from: 1,
        to: 75
    },
    low: {
        from: 76,
        to: 125
    },
    middle1: {
        from: 126,
        to: 199
    },
    middle: {
        from: 200,
        to: 349
    },
    high: {
        from: 350,
        to: 5000
    }
}

export function findGroup(points) {
    const key = Object.keys(groups).find((i) => {
        const value = groups[i]
        return points <= value.to && points >= value.from
    })
    return groups[key]
}

function groupAvr(i, points) {
    const list = []
    const poc = i.points_on_contract.split(",")
    const pp = i.price_per_point.split(",")
    poc.forEach((p, i) => {
        list.push({
            points_on_contract: Number(p),
            price_per_point: Number(pp[i])
        })
    })
    let group = findGroup(points)
    const { from, to } = group
    const newList = list.filter((i) => i.points_on_contract >= from && i.points_on_contract <= to)
    const sum = newList.reduce((r, i) => {
        return r + i.price_per_point
    }, 0)
    const res = (sum || 1) / (newList.length || 1)
    if (res === 1) {
        return null
    }
    return res
}

export function getAvr(i, points_on_contract) {
    if (i.price_per_point) {
        return groupAvr(i, points_on_contract)
    } else {
        return i.averagePP ? i.averagePP : i.averagePrice / points_on_contract
    }
}

export function prepareData(statistic, listing, mobile) {
    if (!isBrowser) return {}
    const limit = mobile ? -6 : -20
    const { resort, price_per_point, points_on_contract } = listing
    const { datasets, labels } = prepareDataMultiple(
        {
            [listing.resort.id]: {
                data: statistic,
                resort: listing.resort.id
            }
        },
        mobile,
        points_on_contract
    )

    const { directPrice } = resort
    const dataCount = labels.length
    const current = new Array(dataCount).fill(null)
    current[current.length - 1] = price_per_point
    const directItems = new Array(dataCount).fill(directPrice).slice(limit)

    /**
     *
     * @type {{datasets: import("chart.js").ChartDataset[], labels: string[]}}
     */
    const res = {
        labels: labels.slice(limit),
        datasets: [
            {
                spanGaps: true,
                segment,
                hidden: true,
                enabled: true,
                label: "Disney direct price",
                data: directItems,
                borderColor: "#CC78FF",
                backgroundColor: "#CC78FF",
                pointStyle: "circle",
                tension: 0.4,
                pointRadius: 7,
                borderWidth: 2,
                pointBorderWidth: 1.75,
                pointBorderColor: "#30254C",
                pointHoverRadius: 10
            }
        ]
    }
    const star = new Image(20, 20)
    star.src = "/star.svg"
    res.datasets.push({
        spanGaps: true,
        segment,
        enabled: true,
        label: "This contract ",
        datalabels: {
            color: "#00FFD1",
            labels: {
                that: {
                    offset: 10,
                    anchor: "center",
                    align: "left",
                    formatter: (value) => {
                        return "This contract: " + addCurrency(value)
                    }
                }
            }
        },
        data: current.slice(limit),
        borderColor: "#00FFD1",
        backgroundColor: "#00FFD1",
        pointStyle: star,
        tension: 0.4,
        pointRadius: 7,
        borderWidth: 2,
        pointBorderWidth: 1.75,
        pointBorderColor: "#30254C",
        pointHoverRadius: 10
    })
    res.datasets.push({
        spanGaps: true,
        segment,
        enabled: true,
        label: "Avg resale price",
        data: datasets[0].data,
        datalabels: {
            anchor: "right",
            align: "top",
            display: (ctx) => {
                if (ctx.chart.showDatalabels || window.innerWidth < 1024) {
                    return ctx.dataIndex % 2
                }
                return false
            },
            formatter: (value) => {
                return addCurrency(Math.round(value))
            },
            color: "#FF7E7E"
        },
        borderColor: "#FF7E7E",
        backgroundColor: "#FF7E7E",
        pointStyle: "circle",
        tension: 0.4,
        pointRadius: 7,
        borderWidth: 2,
        pointBorderWidth: 1.75,
        pointBorderColor: "#30254C",
        pointHoverRadius: 10
    })
    return res
}
