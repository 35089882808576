import { SoloResortChart } from "../SoloResortChart/SoloResortChart"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import cn from "classnames"
import css from "@css/Components.module.scss"
import { getPriceStatistic } from "@lib/api/points-statistic"
import { useGeneralData } from "../../../GeneralData/GeneralData"
import { useMediaQuery } from "@utils/hooks/useMediaQuery"
import { colors, prepareDataMultiple } from "./utils"
import { ResortSelect } from "./ResortSelect"
import { PointsSelect } from "./PointsSelect"

export function MultipleResortChart({
    title = "Average Resale Prices",
    chartOptions,
    className,
    defaultresorts,
    showresorts,
    description,
    defaultprice,
    onlydisney,
    onlynondisney,
    width = 800,
    height = 400
}) {
    const { resorts: _resorts } = useGeneralData()
    const [pp, setPP] = useState(Number(defaultprice || 0))
    const isMobile = useMediaQuery("(max-width: 800px)")

    const resorts = useMemo(() => {
        let resorts = _resorts
        if (showresorts) {
            const limit = showresorts.split(",").map((i) => Number(i.trim()))
            resorts = resorts.filter((i) => limit.includes(i.id))
        }
        if (onlydisney !== undefined) {
            resorts = resorts.filter((i) => !i.nonDisneyWorld)
        }
        if (onlynondisney !== undefined) {
            resorts = resorts.filter((i) => !!i.nonDisneyWorld)
        }
        return resorts
    }, [showresorts, _resorts, onlydisney, onlynondisney])
    const [defaultSelected] = useState(() => {
        const items = (defaultresorts || "").split(",")
        const selected = {}
        items.forEach((i) => {
            selected[i.trim()] = true
        })
        return selected
    })
    const [statistics, setStatistics] = useState(() =>
        Object.keys(defaultSelected)
            .map((i) => ({ resort: i, data: null, loading: false }))
            .reduce((a, b) => ({ ...a, [b.resort]: b }), {})
    )

    const changeResort = useCallback((e) => {
        /*if (Array.isArray(e)) {*/
        setStatistics((old) => {
            const res = { ...old }
            Object.keys(res).forEach((i) => {
                res[i].enabled = false
            })
            e.forEach((i) => {
                res[i] = {
                    loading: false,
                    data: null,
                    resort: i,
                    ...res[i],
                    enabled: true
                }
            })
            return res
        })
        /*        } else {
            const id = e.target.value
            setStatistics((old) => {
                return {
                    ...old,
                    [id]: {
                        loading: false,
                        data: null,
                        resort: id,
                        ...old[id],
                        enabled: !old[id]?.enabled
                    }
                }
            })
        }*/
    }, [])

    useEffect(() => {
        const toLoad = Object.values(statistics)
            .filter((i) => !i.data && !i.loading)
            .reduce((a, b) => {
                a[b.resort] = Object.assign({}, b, { loading: true })
                return a
            }, {})
        const values = Object.values(toLoad)
        if (values.length) {
            setStatistics((old) => {
                return {
                    ...old,
                    ...toLoad
                }
            })
            const requests = values.map((i) => {
                return getPriceStatistic(i.resort)
            })
            Promise.all(requests).then((all) => {
                const statistics = all.reduce((a, b, key) => {
                    const resort = values[key].resort
                    a[resort] = {
                        resort,
                        loading: false,
                        enabled: true,
                        data: b
                    }
                    return a
                }, {})
                setStatistics((old) => {
                    return {
                        ...old,
                        ...statistics
                    }
                })
            })
        }
    }, [statistics])
    const resortsById = useMemo(() => {
        return resorts?.reduce((a, b) => ({ ...a, [b.id]: { ...b, color: colors["--color" + b.id] } }), {})
    }, [resorts])
    const onChangePP = useCallback((pp) => {
        setPP(pp)
    }, [])
    const data = useMemo(() => prepareDataMultiple(statistics, false, pp, resortsById, chartOptions), [statistics, resortsById, pp, chartOptions])
    const lastUpdate = useMemo(() => {
        return data.labels[data.labels.length - 1]
    }, [data])
    const bottom = <PointsSelect defaultActive={Number(defaultprice || 0)} onChange={onChangePP} lastUpdate={lastUpdate} />

    const right = <ResortSelect isMobile={isMobile} resortsById={resortsById} defaultSelected={defaultSelected} onChange={changeResort} />
    const styles = useMemo(() => {
        const h = Math.min(+height, Number(width) * 0.4)
        const w = Math.max(+width, Number(height) * 0.4)
        const s = {
            ["--width"]: w + "px",
            ["--height"]: h + "px"
        }
        Object.values(resortsById).forEach((a) => {
            s["--color" + a.id] = a.color
        })
        return s
    }, [height, width, resortsById])
    if (!resorts) return

    return (
        <div className={cn(css.chartInjectable, isMobile && css.mobile, className)} style={styles}>
            <SoloResortChart
                chartOptions={chartOptions}
                classNames={classNames}
                withCurrent={false}
                data={data}
                title={title}
                showHeaderToggle={false}
                bottom={bottom}
                right={right}
                withUpdates
            />
            {description && <div className={css.description}>{description}</div>}
        </div>
    )
}

const classNames = {
    root: css.chartBody,
    chartParent: css.chartParent,
    bodyWrapper: css.bodyWrapper
}
